<template>
    <div class="card-body py-1">
        <!-- Input -->
        <div class="fib-container border border-bottom-0 overflow-hidden" :style="{ background: '#f7f7f7' }">
            <div class="f-11 text-muted pt-1 text-center mb-0">
                <span id="fill-in-the-blank-label" v-html="instructionText"></span>
            </div>
            <div class="p-2 word-tags-wrapper d-flex align-items-start">
                <div v-show="localData.type === 'partial_word'" class="mr-2">
                    <div>
                        <b-button
                            v-if="hasUnderlinedWords"
                            variant="primary"
                            size="sm"
                            class="f-11 px-1 py-0"
                            @click="removeUnderline"
                        >
                            Clear
                        </b-button>
                    </div>
                </div>
                <div class="d-flex flex-wrap user-select-none">
                    <div
                        v-for="(word, wordIndex) in localData.words"
                        :key="wordIndex"
                        class="position-relative ml-1"
                        style="z-index: 0; height: fit-content"
                    >
                        <span v-if="localData.type === 'whole_word'" class="mr-1">
                            <span>
                                <input
                                    v-show="editingOn === wordIndex"
                                    :ref="'word-editor-' + wordIndex"
                                    v-model="wordInput"
                                    aria-labelledby="fill-in-the-blank-label"
                                    style="min-width: fit-content"
                                    @focus="wordInput = word.value"
                                    @keydown.enter="onWordChanged($event, wordIndex)"
                                    @blur="onWordChanged($event, wordIndex)"
                                />
                                <span
                                    v-show="editingOn !== wordIndex && getWordBody(word.value).trim().length"
                                    class="tags-input-tag user-select-none pointer w-auto"
                                    :class="{ blank: blankWordBody(word) }"
                                    @click="toggleWordBody(wordIndex)"
                                >
                                    <span>{{ getWordBody(word.value) }}</span>
                                </span>
                            </span>
                            <span v-if="editingOn !== wordIndex && endsWithSpecialCharacter(word.value)">
                                <span
                                    v-show="getLastCharacter(word.value).trim().length"
                                    class="tags-input-tag user-select-none pointer w-auto"
                                    :class="{ blank: blankWordLastCharacter(word) }"
                                    @click="toggleLastCharacter(wordIndex)"
                                >
                                    <span>{{ getLastCharacter(word.value) }}</span>
                                </span>
                            </span>
                        </span>

                        <div v-else-if="word.value && word.value.trim()" class="user-select-none">
                            <span v-for="(letter, letterIdx) in word.value.split('')" :key="letter + letterIdx">
                                <span
                                    class="f-12 word-letter"
                                    :class="{
                                        'blank-letter': letterInPartials(word, letterIdx),
                                        'unblank-letter': letterInSpecificFilter(wordIndex, letterIdx),
                                    }"
                                    @click="toggleWordPartialLetter(wordIndex, letterIdx)"
                                >
                                    {{ letter }}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex" @drop="(e) => e.preventDefault()">
            <VueEditor
                v-show="!abTestsLoading"
                :ref="`widget_${index}`"
                v-model="newWord"
                class="tags-input-text bg-white"
                :editor-options="vueEditorCustomOptions"
                @text-change="onChangeWordInput"
            />
        </div>
        <!-- /Input -->

        <!-- Options -->
        <div class="d-flex mt-2 justify-content-end">
            <IncludeWordbank :index="index" @updateData="updateWordBankData" />
        </div>

        <div class="d-flex justify-content-between mt-2">
            <div>
                <label class="font-weight-bold mb-0 f-11 text-muted" :class="{ 'text-disabled': optionInputsDisabled }">
                    Blank Options
                </label>
                <b-form-radio
                    v-model="localData.type"
                    name="type"
                    value="whole_word"
                    class="mr-3"
                    :disabled="optionInputsDisabled"
                >
                    <div class="f-14" style="height: 1.5rem; line-height: 1.5rem">Hide entire word</div>
                </b-form-radio>

                <b-form-radio v-model="localData.type" name="type" value="partial_word" :disabled="optionInputsDisabled">
                    <div class="f-14" style="height: 1.5rem; line-height: 1.5rem">Hide some letters only</div>
                </b-form-radio>
            </div>

            <div>
                <label class="font-weight-bold mb-0 f-11 text-muted" :class="{ 'text-disabled': optionInputsDisabled }">
                    Answer Option
                </label>
                <b-form-checkbox
                    v-model="localData.multiple_choice"
                    class="f-14"
                    name="multiple_choice"
                    switch
                    :disabled="optionInputsDisabled"
                    @change="updateData"
                >
                    Multiple Choice
                </b-form-checkbox>
            </div>
        </div>
        <!-- /Options -->

        <!-- Multiple Choice -->
        <fieldset v-if="localData.multiple_choice" class="fieldset mt-2">
            <legend>Multiple Choice</legend>
            <label
                class="f-11 text-muted d-flex justify-content-between font-weight-bold mt-2"
                :class="{ 'text-disabled': optionInputsDisabled }"
            >
                <div>Answer Options</div>
                <div>Correct?</div>
            </label>
            <MultiOptions
                v-model="multipleChoices"
                class="mb-1"
                :correct-choice="getRightChoice()"
                :disabled="optionInputsDisabled"
            />
            <div>
                <label
                    class="mb-0 f-11 text-muted d-flex justify-content-between"
                    :class="{ 'text-disabled': optionInputsDisabled }"
                >
                    <div class="font-weight-bold">Number of Columns</div>
                    <div class="text-secondary">{{ localData.number_of_columns }}</div>
                </label>
                <b-form-input
                    v-model="localData.number_of_columns"
                    type="range"
                    min="1"
                    max="4"
                    :disabled="optionInputsDisabled"
                    @change="updateData"
                ></b-form-input>
            </div>
        </fieldset>
        <!-- /Multiple Choice -->

        <!-- Advanced Options Toggle -->
        <AdvancedOptionsToggleButton
            :active="showAdvancedOptions"
            :disabled="optionInputsDisabled"
            @click="showAdvancedOptions = !showAdvancedOptions"
        />
        <!-- /Advanced Options Toggle -->

        <!-- Advanced Options -->
        <fieldset v-if="showAdvancedOptions" class="fieldset mt-2">
            <legend>Styles</legend>

            <div class="d-flex justify-content-between">
                <fieldset>
                    <label class="font-weight-bold f-11 text-muted mb-1" :class="{ 'text-disabled': optionInputsDisabled }">
                        Response Line
                    </label>
                    <div>
                        <b-form-radio v-model="localData.answer_type" value="blank" :disabled="optionInputsDisabled">
                            <div class="f-14" style="height: 1.5rem; line-height: 1.5rem">
                                Solid
                                <u>_____</u>
                            </div>
                        </b-form-radio>
                        <b-form-radio v-model="localData.answer_type" value="letter_blanks" :disabled="optionInputsDisabled">
                            <div class="f-14 individual-letters" style="height: 1.5rem; line-height: 1.5rem">
                                Individual letters
                                <span>_ _ _</span>
                            </div>
                        </b-form-radio>
                        <b-form-radio v-model="localData.answer_type" value="none" :disabled="optionInputsDisabled">
                            <div class="f-14" style="height: 1.5rem; line-height: 1.5rem">Blank space</div>
                        </b-form-radio>
                    </div>
                </fieldset>

                <fieldset v-if="localData.answer_type === 'letter_blanks'" class="letter-blanks-options">
                    <label class="font-weight-bold f-11 text-muted mb-1" :class="{ 'text-disabled': optionInputsDisabled }">
                        Letter Blank
                    </label>
                    <div>
                        <b-form-radio v-model="localData.blank_style" value="underline" :disabled="optionInputsDisabled">
                            <div class="f-14" style="height: 1.5rem; line-height: 1.5rem">Line</div>
                        </b-form-radio>
                        <b-form-radio v-model="localData.blank_style" value="box" :disabled="optionInputsDisabled">
                            <div class="d-flex align-items-center justify-content-center">
                                <span class="f-14 mr-2" style="height: 1.5rem; line-height: 1.5rem">Box</span>
                                <PremiumMarker
                                    v-if="!hasFeature('formatting', document, document.entity_type)"
                                    name="fill-in-the-blank|box"
                                />
                            </div>
                        </b-form-radio>
                        <b-form-radio v-model="localData.blank_style" value="circle" :disabled="optionInputsDisabled">
                            <div class="d-flex align-items-center justify-content-center">
                                <span class="f-14 mr-2" style="height: 1.5rem; line-height: 1.5rem">Circle</span>
                                <PremiumMarker
                                    v-if="!hasFeature('formatting', document, document.entity_type)"
                                    name="fill-in-the-blank|circle"
                                />
                            </div>
                        </b-form-radio>
                    </div>
                </fieldset>
            </div>
        </fieldset>
        <!-- /Advanced Options -->
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import { mapGetters } from 'vuex'
import autowidth from 'vue-input-autowidth'
import MultiOptions from '../MultiOptions.vue'
import widthOfText from '../../mixins/widthOfText'
import EditPayWall from '../../mixins/EditPayWall'
import { endsWithSpecialCharacter, getLastCharacter, getWordBody } from '../../helpers/stringUtils'
import PremiumMarker from '../../widgets/premium-marker.vue'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'
import IncludeWordbank from '../IncludeWordbank.vue'

export default defineComponent({
    name: 'FillInTheBlank',
    components: {
        IncludeWordbank,
        VueEditor,
        MultiOptions,
        AdvancedOptionsToggleButton,
        PremiumMarker,
    },
    directives: {
        autowidth,
    },
    mixins: [EditPayWall, widthOfText],
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            wordInput: '',
            newWord: '',
            ranges: [],
            rangesBackup: [],
            editingOn: -1,
            showAdvancedOptions: false,
            defaultInstructionText: '<b>Click</b> on the yellow words to replace them with blanks',
            abTestsLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            allWordbanks: 'document/allWordbanks',
            documentItems: 'document/documentItems',
            abTestInstructionText: 'abtests/fillInTheBlankInstructionText',
            abTestPlaceholderText: 'abtests/fillInTheBlankPlaceholderText',
        }),
        localData: {
            get() {
                return this.data
            },
            set(value) {
                this.localData = value
            },
        },
        vueEditorCustomOptions() {
            return {
                placeholder: this.placeholderText,
                modules: {
                    toolbar: false,
                },
                formats: ['bold', 'italic', 'underline', 'script'],
            }
        },
        instructionText() {
            return getWordBody(this.newWord).length ? this.defaultInstructionText : this.abTestInstructionText
        },
        placeholderText() {
            return this.localData.words.length || this.newWord.trim().length ? '' : this.abTestPlaceholderText
        },
        optionInputsDisabled() {
            return getWordBody(this.newWord).length === 0
        },
        newWordContent: {
            get() {
                return this.newWord.replace(/(<([^>]+)>)/gi, '')
            },
            set(value) {
                this.newWord = value
            },
        },
        multipleChoices: {
            get() {
                return this.localData.choices
            },
            async set(choices) {
                if (!choices.length) {
                    return
                }

                await this.$store.dispatch('document/updateMultipleChoices', {
                    at: this.index,
                    choices,
                })

                this.$emit('change', this.localData)
            },
        },
        hasUnderlinedWords() {
            let underlineWords = this.localData.words.filter((word) => word.partials.length)
            return !!underlineWords.length
        },
    },
    watch: {
        'localData.answer_type': async function () {
            await this.$store.dispatch('document/storeDocumentState')
        },
        'localData.type': async function (newType) {
            if (this.localData.type === 'partial_word' && this.localData.multiple_choice) {
                this.localData.answer_type = 'blank'
            }

            const blankWords = this.localData.words?.filter((w) => w.partials.length)

            // TODO: Refactor this
            if (blankWords?.length) {
                if (newType === 'whole_word') {
                    for (let word of blankWords) {
                        if (word.partials && word.partials.length) {
                            const special = endsWithSpecialCharacter(word.value)
                            let firstPartial = word.partials[0]
                            if (special) {
                                let newPartials = []
                                const wordLen = word.value.length

                                for (let partial of word.partials) {
                                    if (partial.start === 0 && partial.end >= wordLen - 1) {
                                        newPartials.push({
                                            start: 0,
                                            end: wordLen - 1,
                                        })
                                    } else if (partial.end === wordLen && partial.start <= wordLen - 1) {
                                        newPartials.push({
                                            start: wordLen - 1,
                                            end: wordLen,
                                        })
                                    }
                                }
                                word.partials = newPartials
                            } else if (firstPartial.start !== 0 || firstPartial.end !== word.value.length) {
                                word.partials = []
                            }

                            word.specific_filters = []
                        }
                    }
                }
            }

            await this.$store.dispatch('document/storeDocumentState')
        },
        abTestsLoading: {
            handler(value) {
                if (!value) {
                    this.selectAll()
                }
            },
            immediate: true,
        },
    },
    async created() {
        if (this.isLoggedIn) {
            return
        }

        this.abTestsLoading = true

        if (!window.statsig || !window.statsig?.initCalled) {
            await this.$store.dispatch('abtests/initialize')
        }

        if (window.statsig) {
            await this.loadAbTests()
        }

        this.abTestsLoading = false
    },
    mounted() {
        if (!this.localData.subtitle) {
            return
        }
        this.newWord = this.localData.subtitle
            .split(' ')
            .filter((w) => w.trim())
            .join(' ')

        const quillContainer = document.getElementById('quill-container')

        if (quillContainer) {
            quillContainer.children[0].innerHTML = this.localData.subtitle
                .split(' ')
                .filter((w) => w.trim())
                .join(' ')
        }

        let el = this.$refs[`widget_${this.index}`]?.quill.root

        if (el && this.$refs[`widget_${this.index}`]) {
            let range = document.createRange()
            let sel = window.getSelection()
            if (range) {
                range.setStart(el, 1)
                range.collapse(true)
                if (sel) {
                    sel.removeAllRanges()
                    sel.addRange(range)
                }
            }
        }

        this.$refs[`widget_${this.index}`]?.quill.focus()
    },
    methods: {
        endsWithSpecialCharacter,
        getLastCharacter,
        getWordBody,
        async loadAbTests() {
            await this.$store.dispatch('abtests/loadFillInTheBlankTests')
        },
        removeUnderline() {
            this.localData.words.map((word) => {
                ;(word.partials = []), (word.specific_filters = [])
            })
        },
        async onWordChanged(event, index) {
            this.editingOn = -1
            const words = event.target.value.split(' ').filter((w) => w.trim())
            if (words.length) {
                await this.$store.dispatch('document/insertWordsAt', {
                    at: this.index,
                    index,
                    words,
                })
            }
            this.wordInput = ''
        },
        clearSelection() {
            if (window.getSelection) {
                if (window.getSelection().empty) {
                    // Chrome
                    window.getSelection().empty()
                } else if (window.getSelection().removeAllRanges) {
                    // Firefox
                    window.getSelection().removeAllRanges()
                }
            } else if (document.selection) {
                // IE?
                document.selection.empty()
            }
            this.ranges = []
            this.rangesBackup = []
        },
        getRightChoice() {
            return {
                answer: this.localData.words
                    .filter((w) => w.blank)
                    .map((word) => word.value)
                    .join(', '),
                correct: true,
            }
        },
        editWordAt(index) {
            this.editingOn = index
            this.$nextTick(() => {
                this.$refs['word-editor-' + index][0].focus()
            })
        },
        async toggleWordBody(index) {
            if (index === this.editingOn) return

            const word = this.localData.words[index]
            const partial = endsWithSpecialCharacter(word.value)
                ? {
                      start: 0,
                      end: word.value.length - 1,
                  }
                : {
                      start: 0,
                      end: word.value.length,
                  }

            await this.$store.dispatch('document/toggleWordBlank', {
                at: this.index,
                index,
                partial,
            })
            this.$nextTick(() => {
                this.updateData()
            })
        },
        async toggleLastCharacter(index) {
            if (index === this.editingOn) return

            const word = this.localData.words[index]
            await this.$store.dispatch('document/toggleWordBlank', {
                at: this.index,
                index,
                partial: {
                    start: word.value.length - 1,
                    end: word.value.length,
                },
            })
        },
        updateData() {
            this.localData.subtitle = this.localData.words
                .map((w) => w.value)
                .filter((w) => w)
                .join(' ')
            this.$emit('change', this.localData)
        },
        async onChangeWordInput() {
            await this.$store.dispatch('document/updateWords', {
                content: this.newWordContent.trim(),
                at: this.index,
            })
            this.$emit('change', this.localData)
        },
        getBlankWords() {
            if (!this.localData.words.length) return

            let wordbank_words = []
            this.localData.words
                .filter((wordItem) => wordItem.partials.length)
                .forEach((w) => {
                    if (w.partials && w.value) {
                        for (let partial of w.partials) {
                            let partialword = w.value.substring(partial.start, partial.end)
                            partialword = partialword.trim()
                            if (partialword) {
                                wordbank_words.push(partialword)
                            }
                        }
                    } else {
                        wordbank_words.push(w.value)
                    }
                })
            return wordbank_words
        },
        letterInPartials(word, letterIndex) {
            if (word?.partials) {
                for (let partial of word?.partials) {
                    if (partial.start <= letterIndex && partial.end > letterIndex) {
                        return true
                    }
                }
            }

            return false
        },
        letterInSpecificFilter(wordAt, letter) {
            return this.localData.words[wordAt]?.specific_filters?.includes(letter)
        },
        async toggleWordPartialLetter(wordAt, letterIndex) {
            const { partials, specific_filters } = this.localData.words[wordAt]
            let letterInPartial = false
            let inSpecificFilter = specific_filters.includes(letterIndex)

            for (let partial of partials ? partials : []) {
                if (partial.start <= letterIndex && partial.end > letterIndex) {
                    letterInPartial = true
                    break
                }
            }

            if (inSpecificFilter) {
                await this.$store.dispatch('document/updatePartialWord', {
                    at: wordAt,
                    index: letterIndex,
                    length: 1,
                    itemAt: this.index,
                    exclude: inSpecificFilter,
                })
            } else if (letterInPartial) {
                await this.$store.dispatch('document/updateSpecificFilter', {
                    itemAt: this.index,
                    at: wordAt,
                    letterIndex,
                })
            } else {
                await this.$store.dispatch('document/updatePartialWord', {
                    at: wordAt,
                    index: letterIndex,
                    length: 1,
                    itemAt: this.index,
                    exclude: false,
                })
            }
        },
        blankWordBody(word) {
            if (endsWithSpecialCharacter(word.value)) {
                return !!word?.partials?.find((p) => p.start === 0 && p.end >= word.value.length - 1)
            }

            return !!word?.partials?.find((p) => p.start === 0 && p.end === word.value.length)
        },
        blankWordLastCharacter(word) {
            if (!word?.partials) {
                return false
            }
            if (endsWithSpecialCharacter(word.value)) {
                return !!word.partials.find((p) => p.end === word.value.length && p.start <= word.value.length - 1)
            }
            return false
        },
        updateWordBankData(value) {
            this.$emit('change', value)
        },
        selectAll() {
            this.$nextTick(() => {
                const quill = this.$refs[`widget_${this.index}`]?.quill
                if (!quill) return

                quill.setSelection(0, this.newWord.length)
                quill.focus()
            })
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';
.word-tags-wrapper {
    min-height: 40px;
    .word-letter {
        padding: 0 1px;
        margin-right: 1px;
        background-color: #d6d60488;
        cursor: pointer;
        width: 0.875rem;
        text-align: center;
        display: inline-block;
        &.blank-letter {
            background-color: #dc354588;
        }
        &.unblank-letter {
            background-color: #4287f588;
        }
    }
}
.editor-toolbar {
    &::after {
        display: none !important;
    }
}
.partial-selection {
    font-size: 0.875rem;
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
    cursor: default !important;
}
</style>

<style lang="scss" scoped>
.letter-blanks-options {
    margin-right: 4.3rem;
}
</style>

<style lang="scss" scoped>
.individual-letters span {
    letter-spacing: 0.5px;
}
</style>
